import { useTranslations } from 'next-intl';
import { FC } from 'react';
import cls from './ServiceError.module.css';
import { LinkSVG } from '@/shared/assets/images';
import alert from '@/shared/assets/images/ErrorPages/alert.svg';
import Background from '@/shared/assets/images/ErrorPages/errorsBackground.png';
import lateralLines from '@/shared/assets/images/ErrorPages/lateralLines.png';
import { JeroldSVG } from '@/shared/assets/svg/Main';
import { getRouteWelcome } from '@/shared/const/router';
import { AppImage } from '@/shared/ui/AppImage';
import { Icon } from '@/shared/ui/Icon';
import { VStack } from '@/shared/ui/Stack';
import { Text } from '@/shared/ui/Text';
// eslint-disable-next-line path-checher-ulbi-example/layer-imports
import { Page } from '@/widgets/Page';

export const ServiceError: FC = () => {
  const t = useTranslations();
  // eslint-disable-next-line no-return-assign
  const redirectToMain = () => (window.location.href = getRouteWelcome());

  return (
    <VStack max className={cls.wrapper} align='center'>
      <Icon
        clickable
        Svg={JeroldSVG}
        width={87}
        height={18}
        color='#fff'
        className={cls.logo}
        onClick={redirectToMain}
      />
      <Page relative justify='left'>
        <AppImage src={Background} alt='' className={cls.background} />
        <VStack
          align='center'
          justify='center'
          className={cls['service-error-page']}
        >
          <AppImage src={lateralLines} alt='' />
          <Icon Svg={alert} width={210} height={183} />
          <Text variant='h3' as='h3'>
            {t('wow, something went wrong')}
          </Text>
          <Text variant='body-l' align='center'>
            {t('Looks like this page does not exist')}
          </Text>
          <Icon Svg={LinkSVG} width={24} height={24} color='#D2D9D8' />
          <Text variant='body-m' align='center'>
            {t('Please make sure URL is correct and try again')}
          </Text>
        </VStack>
      </Page>
    </VStack>
  );
};
